import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqAccordion = ({ faqs, group }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("panel0");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const xs = useMediaQuery("(max-width:639px");

  console.log(faqs);

  return (
    <>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.40)",
            marginBottom: "14px",
            borderRadius: "8px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "rgba(0, 0, 0)" }} />}
            sx={{
              height: "62 px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.8)",
                fontFamily: "Inter",
                fontSize: xs ? "16px" : "20px",
                fontWeight: 600,
                padding: "16px 0",
                lineHeight: "19px",
              }}
            >
              {t(`faqs.${group}.${index}.question`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0px 14px 14px 14px" }}>
            <Typography sx={{ fontSize: "16px", fontFamily: "Inter" }}>
              <Trans>{t(`faqs.${group}.${index}.answer`)}</Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FaqAccordion;
