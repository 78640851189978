import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const isLocalStorageEnabled = () => {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, null);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getLangFromGeo = cc => {
    let countryCode = cc.substring(0, 2).toLowerCase();
    const codes = {
      br: "pt-BR",
      ar: "es-AR",
    };
    return codes.hasOwnProperty(countryCode) ? codes[countryCode] : "es";
  };

  const getLanguage = () => {
    let languageCode;
    const geo = window.location.pathname
      .split("/")[1]
      .substring(0, 2)
      .toLowerCase();

    languageCode = getLangFromGeo(geo);

    if (isLocalStorageEnabled()) {
      try {
        if (window.localStorage.getItem("lang")) {
          const languageFromLocalStorage = window.localStorage.getItem("lang");
          languageCode = languageFromLocalStorage === "pt" ? "pt-BR" : "es";

          if (
            geo !== "br" &&
            geo !== "ar" &&
            languageFromLocalStorage === "pt"
          ) {
            languageCode = "pt-LATAM";
          }

          if (geo === "br" && languageFromLocalStorage === "es") {
            languageCode = "es";
          }

          if (geo === "ar") {
            if (languageFromLocalStorage === "pt") {
              languageCode = "pt-AR";
            } else {
              languageCode = "es-AR";
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return languageCode;
  };

  const [language, setLanguage] = useState(getLanguage);

  const changeLanguage = newLanguage => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    i18n.use(initReactI18next).init({
      lng: language,
      resources: {
        "pt-BR": {
          translation: require("./translations/pt-BR.json"),
        },
        "pt-AR": {
          translation: require("./translations/pt-AR.json"),
        },
        "pt-LATAM": {
          translation: require("./translations/pt-LATAM.json"),
        },
        "es-AR": {
          translation: require("./translations/es-AR.json"),
        },
        es: {
          translation: require("./translations/es.json"),
        },
      },
      interpolation: {
        escapeValue: false,
      },
    });
  }, [language]);

  const contextValue = {
    language,
    setLanguage: changeLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
