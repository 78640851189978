/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "@mui/material";
import "../assets/css/tailwind.css";
import "../assets/css/core.css";
import Screen1 from "./screen1";
import Screen2 from "./screen2";
import feature1 from "../assets/img/Feature1.svg";
import feature1mobile from "../assets/img/Feature1mobile.svg";

const FaqsComponent = () => {
  const { t, i18n } = useTranslation();

  let url = window.location.href;
  const geo = url.split("/")[3] || "ar";

  const [currentScreen, setCurrentScreen] = useState(null);

  const goToScreen = (screen_number) => {
    setCurrentScreen(`screen${screen_number}`);
  };

  const goBack = () => {
    setCurrentScreen(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);

  return (
    <div className="home-container bg-[#EFF1F4] mb-20">
      <div className="home-features  max-w-[630px] xl:max-w-[1010px] mx-auto md:pt-20 min-h-screen">
        <Slide
          direction="left"
          in={currentScreen === "screen1"}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Screen1 goBack={goBack} />
          </div>
        </Slide>
        <Slide
          direction="left"
          in={currentScreen === "screen2"}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Screen2 goBack={goBack} />
          </div>
        </Slide>
        {!currentScreen && (
          <div className="flex flex-col gap-2 w-full px-4 pb-12">
            <button className="mx-0" type="button">
              <div class="home-btn text-365-violet-hard text-base font-semibold mb-8 flex flex-row items-center pl-4  mt-6 gap-2 ">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                    fill="#675CE5"
                  />
                </svg>
                <h3 className="text-[#675CE5] " data-dt="products_back2home ">
                  <a href={`/${geo}`}>{t("index.back")}</a>
                </h3>
              </div>
            </button>
            <h1 className="text-3xl md:text-4xl xl:text-6xl text-[#2454AD] text-center font-bold pb-6 font-titillium">
              {t("index.title")}
            </h1>

            <div className="feature-box-1 flex flex-col place-items-center  gap-y-4  px-4 pb-8  md:pb-24 ">
              <div className="lg:max-w-[577px]">
                <p className="text-base  font-normal text-center  text-[#3122A56] pb-8 font-titillium ">
                  {t("index.subtitle")}
                </p>
              </div>
              <img src={feature1} alt="Banner" className="hidden md:block" />
              <img
                src={feature1mobile}
                alt="Banner"
                className="md:hidden block"
              />
            </div>
            <button
              onClick={() => goToScreen(1)}
              className="accordion card-shadow rounded-lg mb-4 accordion-header flex justify-between items-center h-[80px] bg-[#f7f7f7] p-4 text-base md:text-2xl text-[#006FE8] font-semibold font-titillium "
            >
              {t("coverages")} <span className="pr-4"> &gt;</span>
            </button>
            <button
              onClick={() => goToScreen(2)}
              className="accordion card-shadow rounded-lg mb-4 accordion-header flex justify-between items-center h-[80px] bg-[#f7f7f7] p-4 text-base md:text-2xl text-[#006FE8] font-semibold font-titillium"
            >
              {t("changes")} <span className="pr-4"> &gt;</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
};

export default FaqsComponent;
