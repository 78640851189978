// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBR from "../context/translations/pt-BR.json";
import ptAR from "../context/translations/pt-AR.json";
import esAR from "../context/translations/es-AR.json";
import es from "../context/translations/es.json";
import ptLATAM from "../context/translations/pt-LATAM.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      "pt-BR": {
        translation: ptBR,
      },
      "pt-AR": {
        translation: ptAR,
      },
      "es-AR": {
        translation: esAR,
      },
      "pt-LATAM": {
        translation: ptLATAM,
      },
      es: {
        translation: es,
      },
    },
    lng: "pt-BR", // default language
    fallbackLng: "pt-BR", // fallback language in case translation not found
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
