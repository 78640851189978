import React from "react";
import { useTranslation } from "react-i18next";
import FaqComponent from "./faq/index.js";
import { useLanguage } from "../context/LanguageContext";

const Screen2 = ({ goBack }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <div className="px-4 pb-12 min-h-screen">
      <button onClick={goBack} className="mx-0 " type="button">
        <div class="home-btn text-365-violet-hard text-base font-semibold mb-8 flex flex-row items-center pl-4  mt-6 gap-2 ">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
              fill="#675CE5"
            />
          </svg>
          <h3 className="text-[#675CE5]" data-dt="products_back2home">
            {t("index.back")}
          </h3>
        </div>
      </button>
      <h1 className="font-titillium text-3xl md:text-4xl xl:text-6xl text-[#2454AD] text-center font-bold pb-6">
        {t("changes")}
      </h1>

      <FaqComponent group={2} />
    </div>
  );
};

export default Screen2;
