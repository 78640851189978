// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-shadow {
  -webkit-box-shadow: 0px 4px 32px 0px rgba(31, 55, 76, 0.2);
  box-shadow: 0px 4px 32px 0px rgba(31, 55, 76, 0.2);
  border: 4rem solid 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/core.css"],"names":[],"mappings":"AAGA;EACE,0DAA0D;EAC1D,kDAAkD;EAClD,kCAAkC;AACpC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap\");\n\n.card-shadow {\n  -webkit-box-shadow: 0px 4px 32px 0px rgba(31, 55, 76, 0.2);\n  box-shadow: 0px 4px 32px 0px rgba(31, 55, 76, 0.2);\n  border: 4rem solid 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
