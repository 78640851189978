import FaqsContent from "./FaqsContent";
import FaqAccordion from "./FaqAccordion";

const FaqComponent = ({ group }) => {
  const lang = "global"; // language hardcoded
  console.log(FaqsContent(lang, `group${group}`));

  return (
    <div className="w-full mx-auto max-w-[358px] sm:max-w-[633px] sm:mt-20  xl:mt-32 xl:max-w-[1210px]  ">
      <FaqAccordion
        faqs={FaqsContent(lang, `group${group}`)}
        group={`group${group}`}
      />
    </div>
  );
};

export default FaqComponent;
