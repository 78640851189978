const FaqsContent = (lang, group) => {
  const faqs = {
    global: {
      group1: 8,
      group2: 4,
    },
    pt: {
      group1: 8,
      group2: 4,
    },
  };

  const resp = [...Array(faqs[lang][group]).keys()];
  return resp;
};

export default FaqsContent;
